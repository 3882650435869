// src/env/index.ts
var CLIENT_VERSION = false ? "?" : '"1.1.0"';
var KEY = typeof FLARE_JS_KEY === "undefined" ? "" : FLARE_JS_KEY;
var SOURCEMAP_VERSION = typeof FLARE_SOURCEMAP_VERSION === "undefined" ? "" : FLARE_SOURCEMAP_VERSION;

// src/util/assert.ts
function assert(value, message, debug) {
  if (debug && !value) {
    console.error(`Flare JavaScript client v${CLIENT_VERSION}: ${message}`);
  }
  return !!value;
}

// src/util/assertKey.ts
function assertKey(key, debug) {
  return assert(key, "The client was not yet initialised with an API key. Run client.light('<flare-project-key>') when you initialise your app. If you are running in dev mode and didn't run the light command on purpose, you can ignore this error.", debug);
}

// src/util/assertSolutionProvider.ts
function assertSolutionProvider(solutionProvider, debug) {
  return assert("canSolve" in solutionProvider, "A solution provider without a [canSolve] property was added.", debug) && assert("getSolutions" in solutionProvider, "A solution provider without a [getSolutions] property was added.", debug);
}

// src/util/flatJsonStringify.ts
function flatJsonStringify(json) {
  let cache = [];
  const flattenedStringifiedJson = JSON.stringify(json, function (_, value) {
    if (typeof value === "object" && value !== null) {
      if (cache.indexOf(value) !== -1) {
        try {
          return JSON.parse(JSON.stringify(value));
        } catch (error) {
          return;
        }
      }
      cache.push(value);
    }
    return value;
  });
  cache = null;
  return flattenedStringifiedJson;
}

// src/util/flattenOnce.ts
function flattenOnce(array) {
  return array.reduce((flat, toFlatten) => {
    return flat.concat(toFlatten);
  }, []);
}

// src/util/now.ts
function now() {
  return Math.round(Date.now() / 1e3);
}

// src/api/Api.ts
var Api = class {
  report(report, url, key, reportBrowserExtensionErrors) {
    return fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-Api-Token": key ?? "",
        "X-Requested-With": "XMLHttpRequest",
        "X-Report-Browser-Extension-Errors": JSON.stringify(reportBrowserExtensionErrors)
      },
      body: flatJsonStringify({
        ...report,
        key
      })
    }).then(response => {
      if (response.status !== 204) {
        console.error(`Received response with status ${response.status} from Flare`);
      }
    }, error => console.error(error));
  }
};

// src/context/cookie.ts
function cookie() {
  if (!window.document.cookie) {
    return {};
  }
  return {
    cookies: window.document.cookie.split("; ").reduce((cookies, cookie2) => {
      const [cookieName, cookieValue] = cookie2.split(/=/);
      cookies[cookieName] = cookieValue;
      return cookies;
    }, {})
  };
}

// src/context/request.ts
function request() {
  return {
    request: {
      url: window.document.location.href,
      useragent: window.navigator.userAgent,
      referrer: window.document.referrer,
      readyState: window.document.readyState
    }
  };
}

// src/context/requestData.ts
function requestData() {
  if (!window.location.search) {
    return {};
  }
  const queryString = {};
  new URLSearchParams(window.location.search).forEach((value, key) => {
    queryString[key] = value;
  });
  return {
    request_data: {
      queryString
    }
  };
}

// src/context/collectContext.ts
function collectContext(additionalContext) {
  if (typeof window === "undefined") {
    return additionalContext;
  }
  return {
    ...cookie(),
    ...request(),
    ...requestData(),
    ...additionalContext
  };
}

// src/solutions/getSolutions.ts
function getSolutions(solutionProviders, error, extraSolutionParameters = {}) {
  return new Promise(resolve => {
    const canSolves = solutionProviders.reduce((canSolves2, provider) => {
      canSolves2.push(Promise.resolve(provider.canSolve(error, extraSolutionParameters)));
      return canSolves2;
    }, []);
    Promise.all(canSolves).then(resolvedCanSolves => {
      const solutionPromises = [];
      resolvedCanSolves.forEach((canSolve, i) => {
        if (canSolve) {
          solutionPromises.push(Promise.resolve(solutionProviders[i].getSolutions(error, extraSolutionParameters)));
        }
      });
      Promise.all(solutionPromises).then(solutions => {
        resolve(flattenOnce(solutions));
      });
    });
  });
}

// src/stacktrace/createStackTrace.ts
import ErrorStackParser from "error-stack-parser";

// src/stacktrace/fileReader.ts
var cachedFiles = {};
function getCodeSnippet(url, lineNumber, columnNumber) {
  return new Promise(resolve => {
    if (!url || !lineNumber) {
      return resolve({
        codeSnippet: {
          0: `Could not read from file: missing file URL or line number. URL: ${url} lineNumber: ${lineNumber}`
        },
        trimmedColumnNumber: null
      });
    }
    readFile(url).then(fileText => {
      if (!fileText) {
        return resolve({
          codeSnippet: {
            0: `Could not read from file: Error while opening file at URL ${url}`
          },
          trimmedColumnNumber: null
        });
      }
      return resolve(readLinesFromFile(fileText, lineNumber, columnNumber));
    });
  });
}
function readFile(url) {
  if (cachedFiles[url]) {
    return Promise.resolve(cachedFiles[url]);
  }
  return fetch(url).then(response => {
    if (response.status !== 200) {
      return null;
    }
    return response.text();
  }).catch(() => null);
}
function readLinesFromFile(fileText, lineNumber, columnNumber, maxSnippetLineLength = 1e3, maxSnippetLines = 40) {
  const codeSnippet = {};
  let trimmedColumnNumber = null;
  const lines = fileText.split("\n");
  for (let i = -maxSnippetLines / 2; i <= maxSnippetLines / 2; i++) {
    const currentLineIndex = lineNumber + i;
    if (currentLineIndex >= 0 && lines[currentLineIndex]) {
      const displayLine = currentLineIndex + 1;
      if (lines[currentLineIndex].length > maxSnippetLineLength) {
        if (columnNumber && columnNumber + maxSnippetLineLength / 2 > maxSnippetLineLength) {
          codeSnippet[displayLine] = lines[currentLineIndex].substr(columnNumber - Math.round(maxSnippetLineLength / 2), maxSnippetLineLength);
          if (displayLine === lineNumber) {
            trimmedColumnNumber = Math.round(maxSnippetLineLength / 2);
          }
          continue;
        }
        codeSnippet[displayLine] = lines[currentLineIndex].substr(0, maxSnippetLineLength) + "\u2026";
        continue;
      }
      codeSnippet[displayLine] = lines[currentLineIndex];
    }
  }
  return {
    codeSnippet,
    trimmedColumnNumber
  };
}

// src/stacktrace/createStackTrace.ts
function createStackTrace(error, debug) {
  return new Promise(resolve => {
    if (!hasStack(error)) {
      assert(false, "Couldn't generate stacktrace of below error:", debug);
      if (debug) {
        console.error(error);
      }
      return resolve([{
        line_number: 0,
        column_number: 0,
        method: "unknown",
        file: "unknown",
        code_snippet: {
          0: "Could not read from file: stacktrace missing"
        },
        trimmed_column_number: null,
        class: "unknown"
      }]);
    }
    Promise.all(ErrorStackParser.parse(error).map(frame => {
      return new Promise(resolve2 => {
        getCodeSnippet(frame.fileName, frame.lineNumber, frame.columnNumber).then(snippet => {
          resolve2({
            line_number: frame.lineNumber || 1,
            column_number: frame.columnNumber || 1,
            method: frame.functionName || "Anonymous or unknown function",
            file: frame.fileName || "Unknown file",
            code_snippet: snippet.codeSnippet,
            trimmed_column_number: snippet.trimmedColumnNumber,
            class: ""
          });
        });
      });
    })).then(resolve);
  });
}
function hasStack(err) {
  return !!err && (!!err.stack || !!err.stacktrace || !!err["opera#sourceloc"]) && typeof (err.stack || err.stacktrace || err["opera#sourceloc"]) === "string" && err.stack !== `${err.name}: ${err.message}`;
}

// src/Flare.ts
var Flare = class {
  constructor(api = new Api()) {
    this.api = api;
    this.config = {
      key: null,
      version: CLIENT_VERSION,
      sourcemapVersion: SOURCEMAP_VERSION,
      stage: "",
      maxGlowsPerReport: 30,
      reportingUrl: "https://reporting.flareapp.io/api/reports",
      reportBrowserExtensionErrors: false,
      debug: false,
      beforeEvaluate: error => error,
      beforeSubmit: report => report
    };
    this.glows = [];
    this.context = {
      context: {}
    };
    this.solutionProviders = [];
  }
  light(key = KEY, debug = false) {
    this.config.key = key;
    this.config.debug = debug;
    return this;
  }
  configure(config) {
    this.config = {
      ...this.config,
      ...config
    };
    return this;
  }
  test() {
    return this.report(new Error("The Flare client is set up correctly!"));
  }
  glow(name, level = "info", data = []) {
    const time = now();
    this.glows.push({
      name,
      message_level: level,
      meta_data: data,
      time,
      microtime: time
    });
    if (this.glows.length > this.config.maxGlowsPerReport) {
      this.glows = this.glows.slice(this.glows.length - this.config.maxGlowsPerReport);
    }
    return this;
  }
  clearGlows() {
    this.glows = [];
    return this;
  }
  addContext(name, value) {
    this.context.context[name] = value;
    return this;
  }
  addContextGroup(groupName, value) {
    this.context[groupName] = value;
    return this;
  }
  registerSolutionProvider(solutionProvider) {
    if (!assertSolutionProvider(solutionProvider, this.config.debug)) {
      return this;
    }
    this.solutionProviders.push(solutionProvider);
    return this;
  }
  async report(error, context = {}, extraSolutionParameters = {}) {
    const errorToReport = await this.config.beforeEvaluate(error);
    if (!errorToReport) {
      return;
    }
    const report = await this.createReportFromError(error, context, extraSolutionParameters);
    if (!report) {
      return;
    }
    return this.sendReport(report);
  }
  async reportMessage(message, context = {}, exceptionClass = "Log") {
    const stackTrace = await createStackTrace(Error(), this.config.debug);
    stackTrace.shift();
    this.sendReport({
      notifier: `Flare JavaScript client v${CLIENT_VERSION}`,
      exception_class: exceptionClass,
      seen_at: now(),
      message,
      language: "javascript",
      glows: this.glows,
      context: collectContext({
        ...context,
        ...this.context
      }),
      stacktrace: stackTrace,
      sourcemap_version_id: this.config.sourcemapVersion,
      solutions: [],
      stage: this.config.stage
    });
  }
  createReportFromError(error, context = {}, extraSolutionParameters = {}) {
    if (!assert(error, "No error provided.", this.config.debug)) {
      return Promise.resolve(false);
    }
    const seenAt = now();
    return Promise.all([getSolutions(this.solutionProviders, error, extraSolutionParameters), createStackTrace(error, this.config.debug)]).then(result => {
      const [solutions, stacktrace] = result;
      assert(stacktrace.length, "Couldn't generate stacktrace of this error: " + error, this.config.debug);
      return {
        notifier: `Flare JavaScript client v${CLIENT_VERSION}`,
        exception_class: error.constructor && error.constructor.name ? error.constructor.name : "undefined",
        seen_at: seenAt,
        message: error.message,
        language: "javascript",
        glows: this.glows,
        context: collectContext({
          ...context,
          ...this.context
        }),
        stacktrace,
        sourcemap_version_id: this.config.sourcemapVersion,
        solutions,
        stage: this.config.stage
      };
    });
  }
  async sendReport(report) {
    if (!assertKey(this.config.key, this.config.debug)) {
      return;
    }
    const reportToSubmit = await this.config.beforeSubmit(report);
    if (!reportToSubmit) {
      return;
    }
    return this.api.report(reportToSubmit, this.config.reportingUrl, this.config.key, this.config.reportBrowserExtensionErrors);
  }
  // Deprecated, the following methods exist for backwards compatibility.
  set beforeEvaluate(beforeEvaluate) {
    this.config.beforeEvaluate = beforeEvaluate ?? "";
  }
  set beforeSubmit(beforeSubmit) {
    this.config.beforeSubmit = beforeSubmit ?? "";
  }
  set stage(stage) {
    this.config.stage = stage ?? "";
  }
};

// src/browser/catchWindowErrors.ts
function catchWindowErrors() {
  if (typeof window === "undefined") {
    return;
  }
  const flare2 = window.flare;
  if (!window || !flare2) {
    return;
  }
  const originalOnerrorHandler = window.onerror;
  const originalOnunhandledrejectionHandler = window.onunhandledrejection;
  window.onerror = (_1, _2, _3, _4, error) => {
    if (error) {
      flare2.report(error);
    }
    if (typeof originalOnerrorHandler === "function") {
      originalOnerrorHandler(_1, _2, _3, _4, error);
    }
  };
  window.onunhandledrejection = error => {
    if (error.reason instanceof Error) {
      flare2.report(error.reason);
    }
    if (typeof originalOnunhandledrejectionHandler === "function") {
      originalOnunhandledrejectionHandler(error);
    }
  };
}

// src/index.ts
var flare = new Flare();
if (typeof window !== "undefined" && window) {
  window.flare = flare;
  catchWindowErrors();
}
export { Flare, flare };