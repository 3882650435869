import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SettingsSelectors } from '@store/settings';
import { first, switchMap } from 'rxjs/operators';

@Injectable()
export class OrganisationInterceptor implements HttpInterceptor {
    constructor(private settingsSelector: SettingsSelectors) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return this.settingsSelector.organisation$.pipe(
            first(),
            switchMap(organisation => {
                if (!organisation) {
                    return next.handle(request);
                }

                const newReq = request.clone({
                    headers: request.headers.set('Organisation', organisation?.slug)
                });

                return next.handle(newReq);
            })
        );
    }
}
