import { NgModule, Optional, SkipSelf } from '@angular/core';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
import { CoreStoreModule } from '@store/core-store.module';
import { APP_SERVICES } from '@core/services';
import { WithCredentialsInterceptor } from '@core/services/interceptors/with-credentials.interceptor';
import { AppHttpInterceptor } from '@core/services/interceptors/app-http.interceptor';
import { ToastrModule } from 'ngx-toastr';
import { OrganisationInterceptor } from '@core/services/interceptors/organisation.interceptor';

@NgModule({
    imports: [CoreStoreModule, ToastrModule.forRoot()],
    providers: [
        ...APP_SERVICES,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: WithCredentialsInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AppHttpInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: OrganisationInterceptor,
            multi: true
        },
        provideHttpClient(withInterceptorsFromDi())
    ]
})
export class CoreModule {
    constructor(
        private _domSanitizer: DomSanitizer,
        private _matIconRegistry: MatIconRegistry,
        @Optional() @SkipSelf() parentModule?: CoreModule
    ) {
        // Do not allow multiple injections
        if (parentModule) {
            throw new Error('CoreModule has already been loaded. Import this module in the AppModule only.');
        }

        // Register icon sets
        this._matIconRegistry.addSvgIconSet(
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/material-twotone.svg')
        );
        this._matIconRegistry.addSvgIconSetInNamespace(
            'mat_outline',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/material-outline.svg')
        );
        this._matIconRegistry.addSvgIconSetInNamespace(
            'mat_solid',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/material-solid.svg')
        );
        this._matIconRegistry.addSvgIconSetInNamespace(
            'iconsmind',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/iconsmind.svg')
        );
        this._matIconRegistry.addSvgIconSetInNamespace(
            'feather',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/feather.svg')
        );
        this._matIconRegistry.addSvgIconSetInNamespace(
            'heroicons_outline',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/heroicons-outline.svg')
        );
        this._matIconRegistry.addSvgIconSetInNamespace(
            'heroicons_solid',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/heroicons-solid.svg')
        );
        this._matIconRegistry.addSvgIconSetInNamespace(
            'logivice',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/logivice.svg')
        );
        this._matIconRegistry.addSvgIconSetInNamespace(
            'logivice-fill',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/logivice-fill.svg')
        );
        this._matIconRegistry.addSvgIconSetInNamespace(
            'logivice-weather',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/logivice-weather.svg')
        );
    }
}
