import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { environment } from 'environments/environment';
import { AppModule } from 'app/app.module';
import * as Sentry from '@sentry/angular-ivy';
import { flare } from '@flareapp/js';

if (environment.sentryUrl) {
    Sentry.init({
        dsn: environment.sentryUrl,
        release: environment.release.version,
        environment: environment.type,
        integrations: [
            new Sentry.BrowserTracing({
                routingInstrumentation: Sentry.routingInstrumentation
            }),
            new Sentry.Replay({
                maskAllText: false,
                blockAllMedia: false
            })
        ],
        ignoreErrors: ['Non-Error exception captured', 'Http failure'],
        tracesSampleRate: 0.2,
        replaysSessionSampleRate: 0,
        replaysOnErrorSampleRate: 1.0
    });
}

if (environment.flareKey) {
    flare.light(environment.flareKey);
}

if (environment.production) {
    enableProdMode();
}

platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch(err => console.error(err));
