import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, concatMap, map, tap, withLatestFrom } from 'rxjs/operators';
import * as SettingsActions from './settings.actions';
import { AuthDispatchers, AuthSelectors } from '@store/auth';
import { OrganisationService } from '@core/services/resource/organisation.service';
import { of } from 'rxjs';
import { Router } from '@angular/router';
import { AuthService } from '@core/services/resource/auth.service';
import { SettingsSelectors } from '@store/settings/settings.selectors';
import { NgxPermissionsService } from 'ngx-permissions';

@Injectable()
export class SettingsEffects {
    // getPartners$ = createEffect(() =>
    //     this.actions$.pipe(
    //         ofType(SettingsActions.getPartners),
    //         concatMap(action =>
    //
    //             this.authSelectors.userPartners$.pipe(filter(p => !!p), take(1),
    //                 map((partners: IPartner[]) => SettingsActions.setPartners({partners}))
    //             )
    //         )
    //     ));

    loadOrganisation$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SettingsActions.loadOrganisation),
            concatMap(action =>
                this.organisationService
                    .loadOrganisation(action.slug, {
                        with: 'allowedPartners,allowedThreepls,connections'
                    })
                    .pipe(
                        map(organisation => SettingsActions.loadOrganisationSuccess({ organisation })),
                        catchError(error => of(SettingsActions.loadOrganisationFailure(error)))
                    )
            )
        )
    );

    loadOrganisationSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(SettingsActions.loadOrganisationSuccess),
                tap(action => {
                    this.permissionsService.flushPermissions();
                    this.permissionsService.loadPermissions(action.organisation.user_permissions);
                })
            ),
        { dispatch: false }
    );

    loadOrganisationFailure$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(SettingsActions.loadOrganisationFailure),
                tap(action => {
                    this.router.navigateByUrl('/entrance');
                })
            ),
        { dispatch: false }
    );

    applySelectedPartnersChanges$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SettingsActions.applySelectedPartnersChanges),
            withLatestFrom(this.settingsSelectors.organisation$, this.settingsSelectors.selectedPartnersIds$),
            concatMap(([action, organisation, selectedPartnersIds]) =>
                this.organisationService
                    .syncSelectedPartners(organisation.slug, {
                        partner_ids: selectedPartnersIds
                    })
                    .pipe(
                        map(() => SettingsActions.applySelectedPartnersChangesSuccess()),
                        catchError(error => of(SettingsActions.applySelectedPartnersChangesFailure()))
                    )
            )
        )
    );

    refreshDataTablesPageNumCache$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(SettingsActions.applySelectedPartnersChanges),
                tap(() => {
                    Object.keys(localStorage).forEach(key => {
                        if (key.includes('datatable')) {
                            localStorage.removeItem(key);
                        }
                    });
                })
            ),
        { dispatch: false }
    );

    reloadPartnerRelatedData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SettingsActions.applySelectedPartnersChangesSuccess),
            map(() => SettingsActions.refreshPartnerRelatedData())
        )
    );

    constructor(
        private actions$: Actions,
        private authSelectors: AuthSelectors,
        private authDispatchers: AuthDispatchers,
        private authService: AuthService,
        private router: Router,
        private organisationService: OrganisationService,
        private settingsSelectors: SettingsSelectors,
        private permissionsService: NgxPermissionsService
    ) {}
}
